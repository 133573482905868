const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://tg74kc3ivd.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_API_URL: 'https://r9s7k83vxi.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.staging.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://i1hb5vtuq9.execute-api.eu-west-1.amazonaws.com/staging'
  },
};

export default config;